import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'clsx';
import { ActionCreator } from 'redux';
import { TFunction } from 'next-i18next';
import dayjs from 'dayjs';

import { createDisplayPurchasableProductsAction, MovieAction } from '../../modules/movie';
import { uuid } from '../../domain/core';
import { ActionButton } from '../common/Button';
import { useToggleState } from '../../hooks';
import { CannotOrderModal } from '../MovieBrochure/CannotOrderModal';
import { MovieReleasedInTheFutureModal } from '../MovieBrochure/MovieReleasedInTheFutureModal/MovieReleasedInTheFutureModal';

interface OwnProps {
    movieId: uuid;
    shouldFillPendingEvaluations?: boolean;
    canBeOrdered?: boolean;
    pendingEvaluationsCount?: number;
    className?: string;
    textClassName?: string;
    releaseDate?: string;
    t: TFunction;
}

interface DispatchProps {
    displayPurchasableProducts: ActionCreator<MovieAction>;
}

type OrderButtonProps = OwnProps & DispatchProps;

const _OrderButton = ({
    movieId,
    shouldFillPendingEvaluations = false,
    canBeOrdered = true,
    pendingEvaluationsCount,
    className,
    textClassName,
    displayPurchasableProducts,
    releaseDate,
    t,
}: OrderButtonProps): JSX.Element => {
    const [isModalOpen, toggleModal] = useToggleState();
    const [type, setType] = useState<'releaseDate' | 'pendingEvaluations' | undefined>(undefined);

    return (
        <>
            <ActionButton
                className={classNames('OrderButton', className)}
                textClassName={classNames('OrderButton-Text', textClassName)}
                onClick={(): void => {
                    if (shouldFillPendingEvaluations && canBeOrdered) {
                        toggleModal();
                        setType('pendingEvaluations');
                    } else if (releaseDate && dayjs(releaseDate).isAfter()) {
                        toggleModal();
                        setType('releaseDate');
                    } else {
                        displayPurchasableProducts(movieId);
                    }
                }}
                customIconPath="/static/img/logo_basket_white.svg"
                text={t(canBeOrdered ? 'common:orderMovie' : 'movie:moreInformation')}
                tooltip={t('movie:organizeProjection')}
            />
            {type === 'pendingEvaluations' && (
                <CannotOrderModal
                    onClose={toggleModal}
                    isOpen={isModalOpen}
                    pendingEvaluationsCount={pendingEvaluationsCount || 0}
                />
            )}
            {type === 'releaseDate' && releaseDate && (
                <MovieReleasedInTheFutureModal
                    type="order"
                    onClose={toggleModal}
                    isOpen={isModalOpen}
                    releaseDate={releaseDate}
                />
            )}
        </>
    );
};

export const OrderButton = connect(
    null,
    { displayPurchasableProducts: createDisplayPurchasableProductsAction }
)(_OrderButton) as FunctionComponent<OwnProps>;
