import React, { ReactNode } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../common/ModalDialog';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import { OrderOptions } from './OrderOptions';
import { withTranslation } from '../../i18n';
import { WithTranslation } from 'next-i18next';
import { VideoProduct, CartEntry, ProductType } from '../../domain/commerce';
import { connect } from 'react-redux';
import { AppState } from '../../store/reducers';
import { ActionCreator } from 'redux';
import { createAddOrUpdateCartItemAction, CartItemCRUDAction } from '../../modules/cart';
import { MovieStateData } from '../../modules/movie';
import { Loader } from '../Loader';
import { MovieCatalog } from '../../domain/work';
import { FrontUser, ProfileType } from '../../domain/user';
import { areNotAvailableYet, haveExpiredRights } from '../../domain/utils/LegalUtils';
import { isUnavailableInUserCountry } from '../../domain/utils/MovieUtils';
import { CreateDigitalOrderAction, createDigitalOrderAction } from '../../modules/order';

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

interface StateProps {
    movie?: MovieStateData;
    isLoading: boolean;
    cartItemBeingEdited: CartEntry | null;
    user?: FrontUser;
}

interface DispatchProps {
    createDigitalOrder: ActionCreator<CreateDigitalOrderAction>;
    addOrUpdateCartItem: ActionCreator<CartItemCRUDAction>;
}

export type OrderModalProps = OwnProps & StateProps & DispatchProps & WithTranslation;

const findDefaultSelectedVideoProduct = (
    videoProducts: VideoProduct[] | undefined,
    cartItemBeingEdited: CartEntry | null
): number | null => {
    if (isEmpty(videoProducts)) {
        return null;
    }

    if (
        !isNil(videoProducts) &&
        videoProducts.length === 1 &&
        videoProducts[0].type.productType === ProductType.DIGITAL
    ) {
        return 0;
    }

    if (!isNil(cartItemBeingEdited)) {
        return findIndex(videoProducts, { id: cartItemBeingEdited!.videoProduct.id }) || 0;
    }

    return null;
};

const includesAfricanCatalog = (catalogs?: MovieCatalog[]): boolean =>
    findIndex(catalogs, (catalog: MovieCatalog): boolean => catalog.code === 'afr') !== -1;

const findPurchasableVideoProductsByUserRights = (
    user: FrontUser,
    products?: VideoProduct[],
    catalogs?: MovieCatalog[]
): VideoProduct[] => {
    if (!products) {
        return [];
    }
    if (user.profileType === ProfileType.IF || includesAfricanCatalog(catalogs)) {
        return products;
    }
    return products.filter((product: VideoProduct): boolean => product.type.productType === ProductType.DIGITAL);
};

const renderModalBody = ({
    t,
    movie,
    isLoading,
    addOrUpdateCartItem,
    cartItemBeingEdited,
    user,
    createDigitalOrder,
}: OrderModalProps): ReactNode => {
    if (!movie || !user) {
        return null;
    }
    if (!user.enabled) {
        return <p className="OrderModal-Text">{t('order:noOrderPossibleWhenDisabled')}</p>;
    }
    if (isLoading) {
        return <Loader />;
    }

    if (areNotAvailableYet(movie.legalContracts)) {
        return <p>{t('order:soonAvailable')}</p>;
    }

    if (haveExpiredRights(movie.legalContracts)) {
        return <p>{t('order:contractEnded')}</p>;
    }

    if (isUnavailableInUserCountry(movie, user)) {
        return <p>{t('common:notAvailableInYourCountry')}</p>;
    }

    const purchasableVideoProducts = findPurchasableVideoProductsByUserRights(
        user,
        movie.videoProducts,
        movie.catalogs
    );

    const selectedProductIndex = findDefaultSelectedVideoProduct(purchasableVideoProducts, cartItemBeingEdited);

    if (purchasableVideoProducts && purchasableVideoProducts.length) {
        return (
            <OrderOptions
                t={t}
                videoProducts={purchasableVideoProducts}
                legalContracts={movie.legalContracts}
                productQuantity={cartItemBeingEdited ? cartItemBeingEdited.quantity : undefined}
                addOrUpdateCartItem={addOrUpdateCartItem}
                selectedProductIndex={selectedProductIndex}
                user={user}
                placeDigitalProductOrder={createDigitalOrder}
            />
        );
    } else {
        return <p className="OrderModal-Text">{t('order:noVideoProducts')}</p>;
    }
};

const getModalTitle = (props: OrderModalProps): string => {
    if (
        !props.movie ||
        !props.user ||
        haveExpiredRights(props.movie.legalContracts) ||
        areNotAvailableYet(props.movie.legalContracts) ||
        isUnavailableInUserCountry(props.movie, props.user)
    ) {
        return '';
    }
    if (!props.user.enabled) {
        return props.t('order:disabledAccount');
    }
    return props.t('order:orderForProjection');
};

const _OrderModal = (props: OrderModalProps): JSX.Element | null => {
    const { isOpen, onClose, t, movie } = props;
    if (!movie) {
        return null;
    }
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    const movieTitle: string = movie.title;

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('order:orderMovieX', { movieTitle })}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            className="OrderModal"
        >
            <ModalDialogHeader className="OrderModal-Header" onClose={handleClose} t={t}>
                {getModalTitle(props)}
            </ModalDialogHeader>
            <ModalDialogBody className="OrderModal-Body">{renderModalBody(props)}</ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    movie: state.movie.data,
    isLoading: state.movie.isLoading,
    cartItemBeingEdited:
        state.cart.indexOfItemBeingEdited !== null ? state.cart.items[state.cart.indexOfItemBeingEdited] : null,
    user: state.profile.data,
});

const mapDispatchToProps: DispatchProps = {
    createDigitalOrder: createDigitalOrderAction,
    addOrUpdateCartItem: createAddOrUpdateCartItemAction,
};

export const OrderModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('order')(_OrderModal));
