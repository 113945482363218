import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import isFunction from 'lodash/isFunction';
import c from 'lodash/upperFirst';
import noop from 'lodash/noop';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
    releaseDate?: string;
    type: 'order' | 'streaming';
}

type MovieReleasedInTheFutureModalProps = OwnProps & WithTranslation;

export const _MovieReleasedInTheFutureModal: FunctionComponent<MovieReleasedInTheFutureModalProps> = ({
    t,
    isOpen,
    onClose,
    releaseDate,
    type,
}: MovieReleasedInTheFutureModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('unauthorizedOrder')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="MovieReleasedInTheFutureModal-Header" onClose={handleClose} t={t}>
                <h2 className="MovieReleasedInTheFutureModal-Header-Title">
                    {c(t(type === 'order' ? 'unauthorizedOrder' : 'unauthorizedStreaming'))}
                </h2>
            </ModalDialogHeader>
            <ModalDialogBody className="MovieReleasedInTheFutureModal-Body">
                <p className="MovieReleasedInTheFutureModal-Body-Content">
                    {t('thisMovieIsAvailableFrom', { date: dayjs(releaseDate).format('DD/MM/YYYY') })}
                </p>
            </ModalDialogBody>
        </ModalDialog>
    );
};

export const MovieReleasedInTheFutureModal = withTranslation('movie')(_MovieReleasedInTheFutureModal);
