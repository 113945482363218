/* eslint-disable react/jsx-no-literals */
import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import isFunction from 'lodash/isFunction';
import c from 'lodash/upperFirst';
import noop from 'lodash/noop';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';

import { ActionButton } from '../../common/Button';
import { Router } from '../../../i18n';

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
    pendingEvaluationsCount?: number;
}

type CannotOrderModalProps = OwnProps & WithTranslation;

export const _CannotOrderModal: FunctionComponent<CannotOrderModalProps> = ({
    t,
    isOpen,
    onClose,
    pendingEvaluationsCount,
}: CannotOrderModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('unauthorizedOrder')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="CannotOrderModal-Header" onClose={handleClose} t={t}>
                <h2 className="CannotOrderModal-Header-Title">{c(t('unauthorizedOrder'))}</h2>
            </ModalDialogHeader>
            <ModalDialogBody className="CannotOrderModal-Body">
                <p className="CannotOrderModal-Body-Content">
                    <span>{t('youHave')}</span>
                    <span className={'CannotOrderModal-Body-Content-PendingEvaluations'}>
                        {t('remainingScreeningsToFill', { count: pendingEvaluationsCount })}
                    </span>
                </p>
                <p className="CannotOrderModal-Body-Content">{t('pleaseFillScreenings')}</p>
                <ActionButton
                    text={c(t('completeScreenings'))}
                    className="CannotOrderModal-Body-ActionButton"
                    onClick={(): void => {
                        Router.push('/screenings');
                        handleClose();
                    }}
                />
                <p className="CannotOrderModal-Body-Footer">{t('ifNoProjection')}</p>
            </ModalDialogBody>
        </ModalDialog>
    );
};

export const CannotOrderModal = withTranslation('movie')(_CannotOrderModal);
