import React, { Component, ChangeEvent } from 'react';
import classNames from 'clsx';
import noop from 'lodash/noop';
import isFinite from 'lodash/isFinite';
import { Button } from '../A11y/Button';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';

library.add(faPlus);
library.add(faMinus);

export interface QuantityPickerProps {
    disabled?: boolean;
    quantity?: number;
    min?: number;
    max?: number;
    className?: string;
    buttonClassName?: string;
    onChange?: (qty: number) => void;
    id?: string;
}

interface QuantityPickerState {
    quantity: number;
}

export class QuantityPicker extends Component<QuantityPickerProps, QuantityPickerState> {
    public static defaultProps: QuantityPickerProps = {
        min: 0,
        onChange: noop,
    };

    public constructor(props: QuantityPickerProps) {
        super(props);
        this.state = {
            quantity: isFinite(this.props.quantity) ? Math.max(this.props.quantity!, this.props.min!) : this.props.min!,
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    private canDecrement(): boolean {
        return this.isValidQuantity(this.state.quantity - 1);
    }

    private canIncrement(): boolean {
        return this.isValidQuantity(this.state.quantity + 1);
    }

    private isValidQuantity(qty: number): boolean {
        return (
            !this.props.disabled &&
            isFinite(qty) &&
            this.props.min! <= qty &&
            (!isFinite(this.props.max) || qty <= this.props.max!)
        );
    }

    private increment(): void {
        if (this.canIncrement()) {
            const quantity = this.state.quantity + 1;
            this.setState({ quantity });
            this.props.onChange!(quantity);
        }
    }

    private decrement(): void {
        if (this.canDecrement()) {
            const quantity = this.state.quantity - 1;
            this.setState({ quantity });
            this.props.onChange!(quantity);
        }
    }

    private handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
        const quantity = parseInt(event.target.value, 10);
        if (this.isValidQuantity(quantity)) {
            this.setState({ quantity });
            this.props.onChange!(quantity);
        }
    }

    public render(): JSX.Element {
        const rootClassName: string = classNames('QuantityPicker', this.props.className);
        return (
            <div className={rootClassName}>
                <Button
                    icon={faMinus}
                    disabled={!this.canDecrement()}
                    className={classNames('QuantityPicker-Button', 'Minus', this.props.buttonClassName)}
                    iconClassName="fa-2x QuantityPicker-Button-Icon"
                    onClick={this.decrement}
                    type="button"
                ></Button>
                <input
                    disabled={this.props.disabled}
                    className="QuantityPicker-Input"
                    min={this.props.min}
                    max={isFinite(this.props.max) ? this.props.max : undefined}
                    name="quantity"
                    onChange={this.handleInputChange}
                    value={this.state.quantity}
                    type="number"
                    id={this.props.id}
                />
                <Button
                    icon={faPlus}
                    iconClassName="fa-2x QuantityPicker-Button-Icon"
                    disabled={!this.canIncrement()}
                    className={classNames('QuantityPicker-Button', 'Plus', this.props.buttonClassName)}
                    onClick={this.increment}
                    type="button"
                ></Button>
            </div>
        );
    }
}
