import React, { useState } from 'react';
import { WithT } from 'i18next';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import { TFunction } from 'next-i18next';
import { ActionCreator } from 'redux';

import { VideoProduct } from '../../domain/commerce';
import { CartItemCRUDAction } from '../../modules/cart';
import { OrderOptionDetails } from './OrderOptionsDetails';
import { LegalContract } from '../../domain/legal';
import { FrontUser } from '../../domain/user';
import { RadioGroup, RadioGroupEntry } from '../common/A11y/RadioGroup';
import { OrderAction } from '../../modules/order';

export interface OrderOptionsProps extends WithT {
    videoProducts: VideoProduct[];
    legalContracts?: LegalContract[];
    productQuantity?: number;
    addOrUpdateCartItem: ActionCreator<CartItemCRUDAction>;
    selectedProductIndex: number | null;
    user: FrontUser;
    placeDigitalProductOrder: ActionCreator<OrderAction>;
}

function getRadioEntries(
    videoProducts: VideoProduct[],
    legalContracts: LegalContract[] | undefined,
    productQuantity: number | undefined,
    addOrUpdateCartItem: ActionCreator<CartItemCRUDAction>,
    t: TFunction,
    selected: number,
    isChangingOption: boolean,
    user: FrontUser,
    placeDigitalProductOrder: ActionCreator<OrderAction>
): RadioGroupEntry<number>[] {
    return map(
        videoProducts,
        (product: VideoProduct, i: number): RadioGroupEntry<number> => ({
            label: product.type.name,
            value: i,
            isSelected: i === selected,
            extraMarkup: (
                <OrderOptionDetails
                    addOrUpdateCartItem={addOrUpdateCartItem}
                    hidden={i !== selected}
                    videoProduct={product}
                    legalContracts={legalContracts}
                    productQuantity={productQuantity}
                    isChangingOption={isChangingOption}
                    t={t}
                    user={user}
                    placeDigitalProductOrder={placeDigitalProductOrder}
                />
            ),
        })
    );
}

export const OrderOptions = ({
    videoProducts,
    legalContracts,
    productQuantity,
    addOrUpdateCartItem,
    selectedProductIndex,
    t,
    user,
    placeDigitalProductOrder,
}: OrderOptionsProps): JSX.Element => {
    const [selected, select] = useState<number>(!isNil(selectedProductIndex) ? selectedProductIndex : -1);
    const isChangingOption = selectedProductIndex !== null;
    return (
        <div className="OrderOptions">
            <RadioGroup
                t={t}
                noOptionSelectedByDefault={selectedProductIndex === null}
                entries={getRadioEntries(
                    videoProducts,
                    legalContracts,
                    productQuantity,
                    addOrUpdateCartItem,
                    t,
                    selected,
                    isChangingOption,
                    user,
                    placeDigitalProductOrder
                )}
                showHorizontal={false}
                onValueChanged={select}
            />
        </div>
    );
};
