import React, { useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'clsx';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { WithT } from 'i18next';
import { TFunction } from 'next-i18next';
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { VideoProduct, CatalogLanguage, ProductType } from '../../domain/commerce';
import { ActionButton } from '../common/Button';
import { CartItemCRUDAction, createAddOrUpdateCartItemAction } from '../../modules/cart';
import { LegalContract } from '../../domain/legal';
import { hasContractExpiredRightsInLessThan30Days } from '../../domain/utils/LegalUtils';
import { QuantityPicker } from '../common/QuantityPicker';
import { FrontUser, ProfileType } from '../../domain/user';
import { ActionCreator } from 'redux';
import { OrderAction } from '../../modules/order';

library.add(faCommentAltLines);

function renderSubtitles(product: VideoProduct, t: TFunction): JSX.Element | null {
    const subs = product.subtitleLanguages;

    if (isEmpty(subs)) {
        return null;
    }
    return (
        <span>
            {t('availableSubtitles')}
            <strong>
                {' ' +
                    map(subs, (sub: CatalogLanguage): string => sub.name)
                        .sort()
                        .join(', ')}
            </strong>
        </span>
    );
}

const renderAdditionalInfo = (videoProduct: VideoProduct): JSX.Element | null => {
    if (isEmpty(videoProduct.additionalInfo)) {
        return null;
    }
    return <span className="OrderOptions-Option-AdditionalInfo">{videoProduct.additionalInfo}</span>;
};

const renderRightsWarning = (legalContracts: LegalContract[] | undefined, t: TFunction): JSX.Element | null => {
    if (legalContracts && legalContracts.length > 0 && hasContractExpiredRightsInLessThan30Days(legalContracts[0])) {
        return (
            <div className="OrderOptions-Option-RigthsWarning">
                {t('rightsExpireInLessThan30Days', {
                    expirationDate: dayjs(legalContracts[0].endingAt).format('MM/DD/YYYY'),
                })}
            </div>
        );
    }
    return null;
};

const renderQuantitySelection = (
    videoProduct: VideoProduct,
    productQuantity: number | undefined,
    setQuantity: React.Dispatch<React.SetStateAction<number>>,
    t: TFunction
): JSX.Element | null => {
    if (ProductType.PHYSICAL !== videoProduct.type.productType) {
        return null;
    }
    const enabledSupports = ['dvd', 'blu-ray'];

    return (
        <div className="OrderOptions-Option-QuantitySelection">
            <label className="OrderOptions-Option-QuantitySelection-Count-Label" htmlFor="">
                {t('numberOfCopiesRequired')}
            </label>
            <QuantityPicker
                className="OrderOptions-Option-QuantitySelection-Count-Picker"
                buttonClassName="OrderOptions-Option-QuantitySelection-Count-Picker-Buttons"
                quantity={productQuantity}
                min={1}
                onChange={(qty: number): void => setQuantity(qty)}
                disabled={!enabledSupports.includes(videoProduct.type.code.toLowerCase())}
            />
        </div>
    );
};

interface OrderOptionDetailsProps extends WithT {
    videoProduct: VideoProduct;
    legalContracts?: LegalContract[];
    productQuantity?: number;
    addOrUpdateCartItem: typeof createAddOrUpdateCartItemAction;
    placeDigitalProductOrder: ActionCreator<OrderAction>;
    isChangingOption: boolean;
    hidden: boolean;
    user: FrontUser;
}

const renderAddToBasketButton = (
    { videoProduct, isChangingOption, placeDigitalProductOrder, addOrUpdateCartItem, t }: OrderOptionDetailsProps,
    productQuantity?: number
): JSX.Element | null => {
    if (videoProduct.type.productType === ProductType.OTHER) {
        return null;
    }

    const buttonTextKey = videoProduct.type.productType === ProductType.DIGITAL ? 'orderAsDownload' : 'addToBasket';

    const addOrUpdateCartItemClick = (): CartItemCRUDAction =>
        addOrUpdateCartItem({ videoProduct: videoProduct, quantity: productQuantity });

    const orderDigitalProduct = (): void => {
        placeDigitalProductOrder(videoProduct);
    };

    return (
        <ActionButton
            text={t(isChangingOption ? 'confirmChoice' : buttonTextKey)}
            className="OrderOptions-Option-Select"
            onClick={
                videoProduct.type.productType === ProductType.DIGITAL ? orderDigitalProduct : addOrUpdateCartItemClick
            }
        />
    );
};

const renderAudioLanguages = (videoProduct: VideoProduct, t: TFunction): JSX.Element | null => {
    const audioLanguages = videoProduct.audioLanguages;

    if (isEmpty(audioLanguages)) {
        return null;
    }
    return (
        <span>
            {t('audioLanguages')}
            <strong>
                {' ' +
                    map(audioLanguages, (audio: CatalogLanguage): string => audio.name)
                        .sort()
                        .join(', ')}
            </strong>
        </span>
    );
};

const renderCreditConsumption = (t: TFunction): JSX.Element => {
    return (
        <span
            className="OrderOptions-Options-Details-Credit"
            dangerouslySetInnerHTML={{
                __html: t('creditConsumption', { interpolation: { escapeValue: false } }),
            }}
        />
    );
};

export const OrderOptionDetails = (props: OrderOptionDetailsProps): JSX.Element => {
    const { user, videoProduct } = props;
    const [productQuantity, setProductQuantity] = useState<number>(props.productQuantity || 1);

    return (
        <>
            <div className={classNames('OrderOptions-Option-Details', { hidden: props.hidden })}>
                <div className="OrderOptions-Option-Details-Infos">
                    {renderAudioLanguages(props.videoProduct, props.t)}
                    {renderSubtitles(props.videoProduct, props.t)}
                </div>
                {renderAdditionalInfo(props.videoProduct)}
                {renderRightsWarning(props.legalContracts, props.t)}
                {renderQuantitySelection(props.videoProduct, productQuantity, setProductQuantity, props.t)}
                {renderAddToBasketButton(props, productQuantity)}
                {videoProduct.type.productType === ProductType.DIGITAL &&
                    user.profileType === ProfileType.PROFESSIONAL &&
                    renderCreditConsumption(props.t)}
            </div>
        </>
    );
};
